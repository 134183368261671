.HypeGraph{
  /*margin-left: 15%;*/

/*  float: left;
  display: block;
*/
  height: 100%;
  /*width: 80%;*/
  /*margin: 50px;*/
/*  background: #2A1D00;
  color: #F9CF6F;
  float: left;
*/}

.red-text {
  fill: red; 
}

.green-text {
  fill: green; 
}

tspan.no-color {
  fill: #555;
}  


.article-bg {
  fill: #fefefe;
  stroke: gray;
  stroke-width: 0px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));

}  


.ArticleDetails {
  width:auto;
  height:300px;
  background-color: #808000;
  padding:10px;
  padding-bottom:20px;
  cursor: default;

}  

.ArticleDetails a {
  text-decoration:none;
} 

.ArticleDetails a:hover {
  text-decoration:underline;
} 

#article-data, .ArticleDetails p {
  font-size:1em;
  line-height:0.5em;
} 

.viz {
  height: 100%;
  cursor: crosshair;
  /* pointer-events: all; */
}

.viz g {
  /* pointer-events: all; */
}  


.nrect {
  pointer-events: all;
}  


/*.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 2px;
}
*/
/*.axisSteelBlue text{
  fill: #667588;
}
*/


.line1, .line2, #p-hor1, #p-hor2, .p-vert, path, circle, rect {
  pointer-events: none;
}  


svg text {
  fill: #667588;
  font-family: HelveticaNeue;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
}

.axisPurple text {
  fill: purple;
}

.bar {
  fill: #4c8eff;
  cursor: pointer;
  pointer-events: all;
}

.bar.selected {
  fill: #eee;
}

.legend text {
  font-size: 18px;
  fill: #F9CF6F;
  text-anchor: middle;
} 

.asset-text {
/*  position: relative;
  x: 142.6px;
  y: 26px;*/
  width: 224px;
  height: 50px;
  rx: 5;
  ry: 5;
  fill: #ee8ad9;
  margin: 11px 501px 3px 39px;
  padding: 9px 24px 7px 11px;
/*  border-radius: 13.2px;
  -webkit-backdrop-filter: blur(5.5px);
  backdrop-filter: blur(5.5px);
  box-shadow: 0 15.5px 31.8px 0 rgba(255, 158, 57, 0.06);
  background-image: linear-gradient(106deg, #fbfbff 9%, rgba(255, 255, 255, 0.83) 83%);*/
}

.asset-text tspan{
  width: 171px;
  height: 34px;
  margin: 0 13px 0 0;
  opacity: 0.9;
/*  font-family: HelveticaNeue;
  font-size: 27.3px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;*/
  color: #4c4c66;
}

.Bgtext-blue {

/*  filter: url(#glow-blue);
  fill: white;
*/
  color: red;
  width: 75px;
  height: 25px;
  margin: 90px 0 36px 1px;
  padding: 4px 1px 6px 9px;
  border-radius: 13.2px;
  -webkit-backdrop-filter: blur(5.5px);
  backdrop-filter: blur(5.5px);
  box-shadow: 0 15.5px 31.8px 0 rgba(83, 185, 234, 0.24);
  background-image: linear-gradient(113deg, #fbfbff 9%, rgba(255, 255, 255, 0.83) 84%);

}  

.Bgtext-red {

  filter: url(#glow-red);
  fill: white;
  text-anchor: end;

}  

.Bgtext-white {

  filter: url(#glow-white);
  fill: black;
  font-size:10pt;
  text-anchor: middle;

} 

.p-volume {

  text-anchor: middle;
  font-size:10px;
  fill: white;
}


/*.tooltip rect {
  fill : white;
  opacity: 0.9;
  pointer-events: none;
} 
*/
.path1,.path2 {
  shape-rendering: crispEdges;
  stroke: currentColor;
  stroke-width: 0.8;
  fill: none;
}  

.p-vert {
  stroke-dasharray: 4,10;
  stroke: #c9c4c4;
  stroke-width:1.5;
}  

#p-hor1 {
  stroke-dasharray: 2,10;
  stroke: #ee8ad9;
  stroke-width: 1;
} 

#p-hor2 {
  stroke-dasharray: 2,10;
  stroke: #8daef5;
  stroke-width:1;
} 

#pc1inner {
  fill: #ee8ad9;
  stroke: #eee;
  stroke-width: 2px;
}

#pc1outer {
  fill: rgba(238, 138, 217, 0.25);
}
#pc2inner {
  fill: #8daef5;
  stroke: #eee;
  stroke-width: 2px;
}

#pc2outer {
  fill: rgba(141, 174, 245, 0.22);
}

/*.tooltip text {
  text-anchor: start;
  font-size: 12px;
  font-weight: bold;   
  -webkit-user-select: none;  
  -moz-user-select: none;     
  -ms-user-select: none;      
  user-select: none; 
  pointer-events: none;
}  
*/
#axis_text_marker_box {
  fill: #fbfbff;
  x: -45;
  /*y: -5;*/
  width: 55px;
  height: 25px;
  margin: 48px 814px 3px 1px;
  padding: 4px 6px 6px 9px;
  rx: 13;
  /*ry: 13;*/
  border-radius: 13.2px;
  -webkit-backdrop-filter: blur(5.5px);
  backdrop-filter: blur(5.5px);
  box-shadow: 0 15.5px 31.8px 0 rgba(255, 57, 223, 0.15);
  background-image: linear-gradient(115deg, #fbfbff 9%, rgba(255, 255, 255, 0.83) 84%);
}

#axis_text_marker_text {
  /*x: -50;*/
  width: 51px;
  height: 15px;
  margin: 0 0 0 7px;
  font-family: HelveticaNeue;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  fill: #6f6c99;
  /*fill: red;*/
}

#axis_text_marker_dot {
  /*r: 10;*/
  stroke: #ee8ad9;
  fill: #ee8ad9;
  x: -3;
  rx: 13;
  /*y: -5;*/
  width: 7px;
  height: 7px;
}

#right_axis_text_marker_box {
  fill: #fbfbff;
  /*x: -45;*/
  width: 62px;
  height: 25px;
  margin: 48px 814px 3px 1px;
  padding: 4px 6px 6px 9px;
  rx: 13;
  border-radius: 13.2px;
  -webkit-backdrop-filter: blur(5.5px);
  backdrop-filter: blur(5.5px);
  box-shadow: 0 15.5px 31.8px 0 rgba(255, 57, 223, 0.15);
  background-image: linear-gradient(115deg, #fbfbff 9%, rgba(255, 255, 255, 0.83) 84%);
}

#right_axis_text_marker_text {
  /*x: -50;*/
  width: 51px;
  height: 15px;
  margin: 0 0 0 7px;
  font-family: HelveticaNeue;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  fill: #6f6c99;
  /*fill: red;*/
}

#right_axis_text_marker_dot {
  /*r: 10;*/
  stroke: #8daef5;
  fill: #8daef5;
  /*x: -1;*/
  rx: 13;
  width: 7px;
  height: 7px;
}

#bottom_axis_text_marker_box {
  fill: #fbfbff;
  /*x: -45;*/
  width: 80px;
  height: 25px;
  margin: 48px 814px 3px 1px;
  padding: 4px 6px 6px 9px;
  rx: 13;
  border-radius: 13.2px;
  -webkit-backdrop-filter: blur(5.5px);
  backdrop-filter: blur(5.5px);
  box-shadow: 0 15.5px 31.8px 0 rgba(255, 57, 223, 0.15);
  background-image: linear-gradient(115deg, #fbfbff 9%, rgba(255, 255, 255, 0.83) 84%);
}

#bottom_axis_text_marker_text {
  /*x: -50;*/
  width: 51px;
  height: 15px;
  margin: 0 0 0 7px;
  font-family: HelveticaNeue;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  fill: #6f6c99;
}

.axisBottom {
  color: #667588;
  stroke-width: 0.1;
}

.axisLeft {
  color: #667588;
  stroke-width: 0.1;
}

.axisRight {
  color: #667588;
  stroke-width: 0.1;
}

#asset_picker {
  fill: red;
  width: 100px;
  height: 330px;
}

.asset_select_button_box {
  width: 20px;
  height: 20px;
  fill: yellow;
}

.asset_select_button_box_white {
  /*fill:  #6f6c99;*/
  /*background-color: rgba(238, 138, 217, 0.25);*/
  width: 200px;
  height: 54px;
  margin: 50px 0 0 23px;
  padding: 0 96px 11px 26px;
}

.asset_select_button_box_grey {
  fill:  #eee;
  width: 200px;
  height: 54px;
  margin: 50px 0 0 23px;
  padding: 0 96px 11px 26px;
}

.asset_select_button_name {
  margin: 50px 0 0 23px;
  padding: 0 96px 11px 26px;
}

.asset_select_button_symbol {
  font-weight: bold;
  margin: 50px 0 0 23px;
  padding: 0 96px 11px 26px;
}

#asset_picker_div {
  height: 800;
  width: 60;
}

.asset_name_text {
  width: 171px;
  height: 34px;
  margin: 0 13px 0 0;
  opacity: 0.9;
  font-family: HelveticaNeue;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4c4c66;
}

.meta_box_dk {
  width: 100px;
  height: 100px;
  x: 0;
  y: 20;
  fill: #eee;
}

.meta_box_price_text {
  font-weight: bold;
}

#meta_box_price_dot {
  x: 0;
  y: 20;
  fill: #8daef5;
}

.meta_box_hype_text {
  font-weight: bold;
}

#meta_box_hype_dot {
  x: 0;
  y: 20;
  fill: #ee8ad9;
}

.meta_box_volume_text {
  font-weight: bold;
}

#meta_box_volume_dot {
  width: 3px;
  height: 10px;
  fill: #4c8eff;
}

