.AssetSelectorButton {
  width: 400px;
  height: 34px;
  padding-top: 6px;
}


.asset_symbol_in_list {
  margin: 0 0 0 10px;
  padding: 0 10px 7px 10px;
  width: 51px;
  height: 29px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.07;
  letter-spacing: 1px;
  text-align: justify;
  color: #6f6c99;
  display: inline-block;
}

.asset_name_in_list {
  margin: 0 0 0 10px;
  padding: 0 10px 7px 10px;
  width: 69px;
  height: 29px;
  /*margin: 13px 23px 0 20px;*/
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.07;
  letter-spacing: 1px;
  text-align: justify;
  color: #6f6c99;
  display: inline;
}