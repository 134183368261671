h1 {
  color: red;
}

.middle_section {

/*  width: 1439.6px;
  height: 1024px;
  margin: 0 0 0 0.4px;
  padding: 15px 0 25px 2.6px;
  object-fit: contain;
  background-image: radial-gradient(circle at 11% 10%, #fff, rgba(254, 254, 255, 0.81) 73%);
*/

  height: 100%;
  width: 85%;
  float: left;
  display: block;
  overflow: scroll;
}

.HiMain {
  background: #fff;
}

/*.line1 {
  fill: none;
  stroke: linear-gradient(to right, rgba(247, 147, 148, 0.22) 0%, rgba(255, 54, 196, 0.47) 19%, #a063c1 100%);
  stroke-width: 2px;
  width: 852px;
  height: 171px;
  margin: 173px 0 28px 9px;
  box-shadow: 0 7.3px 11.8px 0 rgba(12, 10, 32, 0.14);
  border-style: solid;
  border-width: 2.7px;
  border-image-source: linear-gradient(to right, rgba(247, 147, 148, 0.22) 0%, rgba(255, 54, 196, 0.47) 19%, #a063c1 100%);
  border-image-slice: 1;
}

.line2 {
  fill: none;
  stroke-width: 2px;
  width: 859px;
  height: 516px;
  margin: 0 1px;
  box-shadow: 0 7.3px 11.8px 0 rgba(12, 10, 32, 0.14);
  border-style: solid;
  border-width: 2.7px;
  border-image-source: linear-gradient(to right, rgba(175, 147, 247, 0.14) 0%, rgba(54, 105, 255, 0.47) 19%, #63b0c1 100%);
  border-image-slice: 1;
}*/

/*.asset_picker {
  position: relative;
  left: 142.6px;
  top: 26px;
  width: 224px;
  height: 50px;
  margin: 11px 501px 3px 39px;
  padding: 9px 24px 7px 11px;
  border-radius: 13.2px;
  -webkit-backdrop-filter: blur(5.5px);
  backdrop-filter: blur(5.5px);
  box-shadow: 0 15.5px 31.8px 0 rgba(255, 158, 57, 0.06);
  background-image: linear-gradient(106deg, #fbfbff 9%, rgba(255, 255, 255, 0.83) 83%);
}

.asset_picker_text {
  width: 171px;
  height: 34px;
  margin: 0 13px 0 0;
  opacity: 0.9;
  font-family: HelveticaNeue;
  font-size: 27.3px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4c4c66;
}*/