.AssetsList{
  cursor:pointer;
  width: 15%;
  height: 100%;
  /*background: #6FCACD;*/
  float: left;
  overflow: scroll;

 /* left: 50%;
  top: 50%;
  transform: scale(0.5);
  transform-origin: top right;
*/

}

input.AssetSelectorButton  {
  font-size: 16px;
  width:85%;
}  

.asset_button_grey {
  background-color: #eee;
}

.asset_button_white {
  background-color: #fff;
}

.inputAsset {
  width: 17%;
}